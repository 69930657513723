import BannerCTA from "@components/BannerCTA/BannerCTA"
import Intro from "@components/Intro/Intro"
import Layout from "@components/Layout/Layout"
import VehicleCardGroup from "@components/VehicleCardGroup/VehicleCardGroup"
import "@styles/global.scss"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

interface IHeroImage {
  publicURL: string
}
interface IData {
  backgroundHero: IHeroImage
  hero: IHeroImage
}

export default () => {
  const PageTitle: string =
    "Vehicles at the Hagerty Learning Garage | Hagerty Learning Garage"
  const metaDescription: string =
    "From pre-war historics to modern classics, the vehicles at the Hagerty Learning Garage are a sight to behold. Take a look at what’s on display."

  const data: IData = useStaticQuery(graphql`
    {
      backgroundHero: file(relativePath: { eq: "heros/vehicles.jpg" }) {
        childImageSharp {
          fixed(width: 1800, quality: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      hero: file(relativePath: { eq: "heros/vehicles.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout
      metaPageTitle={PageTitle}
      metaDescription={metaDescription}
      darkTheme={true}
    >
      <Intro
        title="Vehicles at the Learning Garage"
        body="History is alive in our vehicles showcased at the Garage. From modern classics to pre-war historics, we’re invested in every era of automobile, and they’re on display here."
        backgroundHero={data.backgroundHero.childImageSharp.fixed.src}
        hero={data.hero.childImageSharp.fluid}
      />
      <VehicleCardGroup />
      <BannerCTA />
    </Layout>
  )
}
