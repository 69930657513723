import VehicleCard from "@components/VehicleCard/VehicleCard"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import "./VehicleCardGroup.scss"

interface IHeroImage {
  publicURL: string
}
interface IData {
  car1: IHeroImage
  car2: IHeroImage
  car3: IHeroImage
  car4: IHeroImage
}

const VehicleCardGroup = () => {
  const data: IData = useStaticQuery(graphql`
    query CollectionList {
      allMdx(sort: { fields: frontmatter___title, order: ASC }) {
        edges {
          node {
            id
            frontmatter {
              title
              path
              hero {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const VehicleList = data.allMdx.edges.map(edge => (
    <Link key={edge.node.id} to={edge.node.frontmatter.path}>
      <VehicleCard
        key={edge.node.id}
        title={edge.node.frontmatter.title}
        img={edge.node.frontmatter.hero.childImageSharp.fluid}
      />
    </Link>
  ))
  return (
    <section className="wrapper vehicle-card-group">
      <div className="vehicle-card-group--two-column">{VehicleList}</div>
    </section>
  )
}

export default VehicleCardGroup
