import React from "react"
import Img from "gatsby-image"

import "./VehicleCard.scss"

interface IVehicleCard {
  title: string
  body: string
  img: string
}

const VehicleCard: React.FC<IVehicleCard> = props => {
  return (
    <div className="vehicle-card">
      <Img fluid={props.img} />
      <h2 className="f-h2">{props.title}</h2>
      {props.body && <p className="f-body2">{props.body}</p>}
    </div>
  )
}

export default VehicleCard
